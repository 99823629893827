
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export default class WorkExperienceEntryHeader extends Vue {
    @Prop() title!: string;
    @Prop() companyName !: string;
    @Prop() address !: string;
    @Prop() duration !: string;
    @Prop() details!: string[];
}
