
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export default class Header extends Vue {
  @Prop() name!: string
  @Prop() address!: string
  @Prop() emailId!: string
  @Prop() phoneNumber!: string
  @Prop() linkedInURL!: string
  @Prop() githubURL!: string

  get mailToLink() {
    return 'mailto:' + this.emailId;
  }
}
