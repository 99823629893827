
import { Options, Vue } from 'vue-class-component';
import EducationEntry from './EducationEntry.vue';

@Options({
  components: {
    EducationEntry
  }
})
export default class Education extends Vue {
  
}
