
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EntryHeader from './EntryHeader.vue'

@Options({components: {EntryHeader}})
export default class EducationEntry extends Vue {
    @Prop() university !: string;
    @Prop() address !: string;
    @Prop() college !: string;
    @Prop() degreeName !: string;
    @Prop() graduationDate !: string;
    @Prop() gpa !: number;
}
