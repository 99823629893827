
import { Options, Vue } from 'vue-class-component';
import TechinicalKnowledgeRow from "./TechinicalKnowledgeRow.vue"

@Options({components: {TechinicalKnowledgeRow}})
export default class  extends Vue {
    get details(){
        return {
            "Languages": ["Python (Proficient)", "SQL (Proficient)", "TypeScript (Proficient)", "Java (Prior Knowledge)"],
            "Databases": ["PostgreSQL", "Google BigQuery", "MongoDB", "Snowflake", "AWS Athena", "Redis"],
            "CI/CD":["CircleCI", "Jenkins"],
            "Systems": ["Docker", "Airflow", "Apache Spark", "Linux", "macOS"],
            "Web Technologies": ["NodeJS", "VueJS", "Django", "Flask", "Nginx"]
        }
    }
}
