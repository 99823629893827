import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntryHeader = _resolveComponent("EntryHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EntryHeader, {
      university: _ctx.university,
      address: _ctx.address,
      college: _ctx.college,
      graduationDate: _ctx.graduationDate
    }, null, 8, ["university", "address", "college", "graduationDate"]),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.degreeName), 1),
      _createElementVNode("span", null, ", GPA: " + _toDisplayString(_ctx.gpa) + "/4.0", 1)
    ])
  ]))
}