
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export default class EducationEntry extends Vue {
    @Prop() university !: string;
    @Prop() address !: string;
    @Prop() college !: string;
    @Prop() graduationDate !: string;
}
