import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container lg:mx-auto px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Education = _resolveComponent("Education")!
  const _component_TechinicalKnowledge = _resolveComponent("TechinicalKnowledge")!
  const _component_WorkExperience = _resolveComponent("WorkExperience")!
  const _component_AcademicProjects = _resolveComponent("AcademicProjects")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      name: "PRATEEK PISAT",
      address: "Boston, MA 02120",
      emailId: "pisat.p@northeastern.edu",
      githubURL: "https://www.github.com/PrateekPisat",
      phoneNumber: "781-605-8640",
      linkedInURL: "https://www.linkedin.com/in/prateekpisat"
    }),
    _createVNode(_component_Education),
    _createVNode(_component_TechinicalKnowledge),
    _createVNode(_component_WorkExperience),
    _createVNode(_component_AcademicProjects)
  ]))
}