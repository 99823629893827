
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WorkExperienceEntryHeader from  './WorkExperienceEntryHeader.vue';

@Options({components: {WorkExperienceEntryHeader}})
export default class WorkExperienceEntry extends Vue {
    @Prop() title!: string;
    @Prop() companyName !: string;
    @Prop() address !: string;
    @Prop() duration !: string;
    @Prop() details!: string[];
}
