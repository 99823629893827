
import { Options, Vue } from 'vue-class-component';
import Header from './components/Header.vue';
import Education from './components/Education.vue';
import TechinicalKnowledge from './components/TechinicalKnowledge.vue';
import WorkExperience from './components/WorkExperience.vue'
import AcademicProjects from './components/AcademicProjects.vue'

@Options({components: {Header, Education, TechinicalKnowledge, WorkExperience, AcademicProjects}})
export default class App extends Vue {}
